exports.components = {
  "component---src-components-page-redirect-js": () => import("./../../../src/components/PageRedirect.js" /* webpackChunkName: "component---src-components-page-redirect-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account/[...].js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-all-js": () => import("./../../../src/pages/all.js" /* webpackChunkName: "component---src-pages-all-js" */),
  "component---src-pages-beloved-frequently-asked-questions-js": () => import("./../../../src/pages/beloved/frequently-asked-questions.js" /* webpackChunkName: "component---src-pages-beloved-frequently-asked-questions-js" */),
  "component---src-pages-beloved-index-js": () => import("./../../../src/pages/beloved/index.js" /* webpackChunkName: "component---src-pages-beloved-index-js" */),
  "component---src-pages-beloved-newsletter-signup-js": () => import("./../../../src/pages/beloved/newsletter-signup.js" /* webpackChunkName: "component---src-pages-beloved-newsletter-signup-js" */),
  "component---src-pages-beloved-wear-and-care-js": () => import("./../../../src/pages/beloved/wear-and-care.js" /* webpackChunkName: "component---src-pages-beloved-wear-and-care-js" */),
  "component---src-pages-blog-shopify-article-handle-js": () => import("./../../../src/pages/blog/{ShopifyArticle.handle}.js" /* webpackChunkName: "component---src-pages-blog-shopify-article-handle-js" */),
  "component---src-pages-blogs-news-[handle]-js": () => import("./../../../src/pages/blogs/news/[handle].js" /* webpackChunkName: "component---src-pages-blogs-news-[handle]-js" */),
  "component---src-pages-bluboho-online-fine-jewelry-signup-js": () => import("./../../../src/pages/bluboho-online-fine-jewelry-signup.js" /* webpackChunkName: "component---src-pages-bluboho-online-fine-jewelry-signup-js" */),
  "component---src-pages-book-a-consultation-js": () => import("./../../../src/pages/book-a-consultation.js" /* webpackChunkName: "component---src-pages-book-a-consultation-js" */),
  "component---src-pages-bridal-stories-index-js": () => import("./../../../src/pages/bridal-stories/index.js" /* webpackChunkName: "component---src-pages-bridal-stories-index-js" */),
  "component---src-pages-bridal-stories-share-the-love-js": () => import("./../../../src/pages/bridal-stories/share-the-love.js" /* webpackChunkName: "component---src-pages-bridal-stories-share-the-love-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-collections-[handle]-js": () => import("./../../../src/pages/collections/[handle].js" /* webpackChunkName: "component---src-pages-collections-[handle]-js" */),
  "component---src-pages-collections-collection-[handle]-products-handle-js": () => import("./../../../src/pages/collections/[collectionHandle]/products/[handle].js" /* webpackChunkName: "component---src-pages-collections-collection-[handle]-products-handle-js" */),
  "component---src-pages-contemplation-cards-js": () => import("./../../../src/pages/contemplation-cards.js" /* webpackChunkName: "component---src-pages-contemplation-cards-js" */),
  "component---src-pages-diamonds-js": () => import("./../../../src/pages/diamonds.js" /* webpackChunkName: "component---src-pages-diamonds-js" */),
  "component---src-pages-earring-mix-and-match-js": () => import("./../../../src/pages/earring-mix-and-match.js" /* webpackChunkName: "component---src-pages-earring-mix-and-match-js" */),
  "component---src-pages-how-to-buy-a-ring-js": () => import("./../../../src/pages/how-to-buy-a-ring.js" /* webpackChunkName: "component---src-pages-how-to-buy-a-ring-js" */),
  "component---src-pages-linked-bracelets-js": () => import("./../../../src/pages/linked-bracelets.js" /* webpackChunkName: "component---src-pages-linked-bracelets-js" */),
  "component---src-pages-locations-index-js": () => import("./../../../src/pages/locations/index.js" /* webpackChunkName: "component---src-pages-locations-index-js" */),
  "component---src-pages-metals-js": () => import("./../../../src/pages/metals.js" /* webpackChunkName: "component---src-pages-metals-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-one-percent-for-the-planet-js": () => import("./../../../src/pages/one-percent-for-the-planet.js" /* webpackChunkName: "component---src-pages-one-percent-for-the-planet-js" */),
  "component---src-pages-our-origin-story-js": () => import("./../../../src/pages/our-origin-story.js" /* webpackChunkName: "component---src-pages-our-origin-story-js" */),
  "component---src-pages-pages-[handle]-js": () => import("./../../../src/pages/pages/[handle].js" /* webpackChunkName: "component---src-pages-pages-[handle]-js" */),
  "component---src-pages-podcasts-js": () => import("./../../../src/pages/podcasts.js" /* webpackChunkName: "component---src-pages-podcasts-js" */),
  "component---src-pages-products-[handle]-js": () => import("./../../../src/pages/products/[handle].js" /* webpackChunkName: "component---src-pages-products-[handle]-js" */),
  "component---src-pages-sample-sale-js": () => import("./../../../src/pages/sample-sale.js" /* webpackChunkName: "component---src-pages-sample-sale-js" */),
  "component---src-pages-sapphires-js": () => import("./../../../src/pages/sapphires.js" /* webpackChunkName: "component---src-pages-sapphires-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-special-offer-js": () => import("./../../../src/pages/special-offer.js" /* webpackChunkName: "component---src-pages-special-offer-js" */),
  "component---src-pages-sustainable-practices-js": () => import("./../../../src/pages/sustainable-practices.js" /* webpackChunkName: "component---src-pages-sustainable-practices-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-wholesale-js": () => import("./../../../src/pages/wholesale.js" /* webpackChunkName: "component---src-pages-wholesale-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/BlogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-collection-group-page-template-js": () => import("./../../../src/templates/CollectionGroupPageTemplate.js" /* webpackChunkName: "component---src-templates-collection-group-page-template-js" */),
  "component---src-templates-collection-page-template-js": () => import("./../../../src/templates/CollectionPageTemplate.js" /* webpackChunkName: "component---src-templates-collection-page-template-js" */),
  "component---src-templates-community-events-js": () => import("./../../../src/templates/CommunityEvents.js" /* webpackChunkName: "component---src-templates-community-events-js" */),
  "component---src-templates-gift-guide-template-js": () => import("./../../../src/templates/GiftGuideTemplate.js" /* webpackChunkName: "component---src-templates-gift-guide-template-js" */),
  "component---src-templates-home-page-template-js": () => import("./../../../src/templates/HomePageTemplate.js" /* webpackChunkName: "component---src-templates-home-page-template-js" */),
  "component---src-templates-location-page-template-js": () => import("./../../../src/templates/LocationPageTemplate.js" /* webpackChunkName: "component---src-templates-location-page-template-js" */),
  "component---src-templates-product-page-template-js": () => import("./../../../src/templates/ProductPageTemplate.js" /* webpackChunkName: "component---src-templates-product-page-template-js" */),
  "component---src-templates-sanity-page-template-js": () => import("./../../../src/templates/SanityPageTemplate.js" /* webpackChunkName: "component---src-templates-sanity-page-template-js" */)
}

